<!--
 * @Author: yanzq
 * @Date: 2021-12-17 23:50:22
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-01-07 10:51:47
 * @Description: 请填写简介
-->
<template>
  <div class="uesrCenter">
    <div class="banner flex-y-s">
      <a href="javasctipt:;" @click="backLevel" class="backBtn">返回</a>
      <a class="homeBtn" @click="backHome" href="javascript:;">首页</a>
      <div>
        <!-- <h3>名称名称</h3> -->
        <p>欢迎您！{{ $store.state.globalInfo.nickname }}</p>
      </div>
    </div>
    <ul>
      <li>
        <div>
          <img src="../../assets/images/userCenter/qy.png" alt="" />
          企业名称
        </div>
        <div>
          <img src="../../assets/detail/jwd.png" alt="" />
          状态
        </div>
        <div>
          <img src="../../assets/images/userCenter/bz.png" alt="" />
          企业群组
        </div>
      </li>
    </ul>
    <van-form @submit="submitBind" class="bind">
      <div class="name">{{ this.waCompanyName }}</div>
      <van-field
        v-model="wxh"
        required
        readonly
        class="flex-block"
        label="微信号昵称："
        placeholder="请输入微信号昵称"
      />
      <van-field
        v-model="phone"
        required
        :readonly="this.info.waBind == '1'"
        label="手机号："
        placeholder="请输入手机号"
        class="flex-block"
      />
      <div style="margin: 16px" class="btn" v-show="this.info.waBind == '0'">
        <van-button
          round
          block
          type="info"
          native-type="button"
          @click="cancelBind"
          >取消</van-button
        >
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
      <div style="margin: 16px" class="btn" v-show="this.info.waBind == '1'">
        <van-button
          style="background: #3295f9; color: #fff"
          round
          block
          type="info"
          native-type="button"
          @click="unBundle"
          >解绑</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import {
  getEnterpriseSupervisionList,
  saveAccountAndCompany,
  getWechatEnterpriseSupervisionCount,
  unboundAccountAndCompany,
} from "../../api/home";
import { Toast } from "vant";
export default {
  data() {
    return {
      wxh: "",
      phone: "",
      waCompanyName: "",
      info: "",
      waUuid: "",
    };
  },

  mounted() {
    this.info = JSON.parse(this.$route.query.item);
    this.waCompanyName = this.info.waCompanyName;
    this.wxh = JSON.parse(localStorage.getItem("userInfo")).nickname || "";
    this.waUuid = this.info.waUuid;
    this.phone = this.info.waAccountPhone;
  },

  methods: {
    unBundle() {
      unboundAccountAndCompany({
        waOpenId: localStorage.getItem("openid"),
        // waOpenId: "o3JQ06uMfbv9nWloUy-sWAczOCkA",
        waShxydm: this.info.waShxydm,
        waBind: 1,
      }).then((res) => {
        if (res.success) {
          this.$toast("解绑成功");
          this.$router.go(-1);
          // this.request();
        } else {
          this.$toast("解绑失败");
        }
      });
    },
    cancelBind() {
      this.$router.push({
        path: "/bindList",
      });
    },
    backLevel(e) {
      e.preventDefault();
      this.$router.push({
        path: "/bindList",
      });
      return false;
    },
    backHome(e) {
      e.preventDefault();
      wx.closeWindow();
      return false;
    },
    submitBind() {
      var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!myreg.test(this.phone)) {
        Toast.fail("请填写正确手机号");
        return false;
      }
      let arr = [
        {
          waCompanyName: this.info.waCompanyName,
          waAccountName: this.info.waAccountName,
          waAccountPhone: this.phone,
          waShxydm: this.info.waShxydm,
          waOpenId: this.info.waOpenId,
          waBind: "1",
          waUuid: this.waUuid,
          waNickname: encodeURIComponent(this.wxh),
        },
      ];
      saveAccountAndCompany(arr).then((res) => {
        // if (res.success) {
        //   this.$router.push({
        //     path: "/bindList",
        //   });
        //
        // }
        if (res.success) {
          this.$toast("绑定成功");
          this.$router.go(-1);
          // this.request();
        } else {
          this.$toast("绑定失败");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  justify-content: center;
  button {
    width: 45%;
    height: rem(80);
    margin: 0 2%;
    &:nth-of-type(1) {
      background: rgb(255, 255, 255);
      border: rem(2) solid rgb(50, 149, 249);
      color: rgb(50, 149, 249);
    }
  }
}
.bind {
  margin: rem(20) auto;
  box-sizing: border-box;
  width: rem(686);
  position: relative;
  top: -40px;
  text-align: center;
  .name {
    font-size: rem(30);
    color: #333;
    padding: rem(20) rem(30);
    background: url("../../assets/images/userCenter/item-bg.png");
    background-size: 100% 100%;
    font-weight: bold;
    font-size: rem(36);
    color: white;
  }
}
.flex-block {
  display: block;
  padding-bottom: 0;
  ::v-deep.van-field__label {
    width: 100%;
    font-size: rem(28);
    color: #333333;
    margin-bottom: 5px;
    text-indent: 10px;
  }
}
::v-deep .van-field__body {
  background: rgba(238, 238, 238, 0.6);
  border-radius: 4px;
  width: 100%;
  height: rem(66);
}
::v-deep .van-cell__value {
  display: flex;
  flex-wrap: wrap !important;
  align-items: center;
}

.banner {
  padding: rem(20);
  width: 100%;
  height: rem(342);
  background: url("../../assets/images/userCenter/banner-bg2.png");
  background-size: 100%;
  color: white;
  .title {
    color: #fff;
    font-size: rem(40);
  }
  p {
    font-size: rem(36);
    font-weight: bold;
  }
  img {
    width: rem(36);
    height: rem(36);
  }
}
.pop-banner {
  display: flex;
  align-items: center;

  ::v-deep .van-field__body {
    width: 250px;
    border: none !important;
    margin-left: 5px;
    background: none;
    position: relative;
    &::before {
      display: block;
      content: "";
      width: 220px;
      position: absolute;
      height: 2px;
      background: white;
      top: 30px;
      left: -25px;
    }
  }

  .van-cell {
    padding: 0;
    background: none;

    display: block !important;
  }
  ::-webkit-input-placeholder {
    color: white !important;
  }
  .van-cell--required::before {
    display: none;
  }
}
::v-deep.van-cell--required .van-field__label::after {
  content: "";
  position: absolute;
  left: rem(36);
  top: rem(30);
  width: rem(6);
  height: rem(28);
  background-image: linear-gradient(179deg, #2d90fb 0%, #84bfff 100%);
  border-radius: 40px;
}
.fix {
  position: fixed;
  bottom: 0;
  left: 0;
  height: rem(96);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.19);

  width: 100%;
  .van-button {
    width: rem(510);
    height: rem(76);
    background-image: linear-gradient(90deg, #2d90fb 0%, #84bfff 100%);
    box-shadow: 0 4px 8px 0 rgba(25, 31, 37, 0.12);
    border-radius: 40px;
  }
}

ul {
  margin: rem(20) auto;
  box-sizing: border-box;
  width: rem(686);
  position: relative;
  top: -40px;
  text-align: center;

  li {
    width: 100%;
    display: flex;
    img {
      width: rem(30);
      height: rem(32);
      position: relative;
      top: 2px;
    }
  }

  div {
    width: 50%;
    text-align: center;
    padding: rem(25) 0;
    font-size: rem(25);
  }
  .blod {
    font-size: rem(24);
    color: #3296fa;
  }
  & > li:first-child {
    background: #fafdff;
    height: rem(96);
    box-shadow: 0 rem(2) rem(16) 0 rgba(0, 0, 0, 0.09);
    border-radius: rem(48);
    position: relative;
    & > div {
      position: relative;
      &:not(:nth-last-of-type(1)):before {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: rem(3);
        height: rem(36);
        background: #3296fa;
      }
    }
  }
}
.uesrCenter {
  padding-bottom: rem(100);
}
::v-deep .van-field__error-message {
  display: none !important;
}
</style>
